* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: "Montserrat", sans-serif;
    background-color: #e8e7e8;
    background-image: url("../public/static/images/negative-space-abstract-shape-pattern-background-5.jpg");
    background-repeat: repeat;
    background-size: cover;
    color: #000;
    height: 100vh;
}
.container {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container p {
    font-size: 4rem;
    /* padding: 0.5rem; */
    text-align: center;
    overflow: hidden;
    letter-spacing: 0.1rem;
    min-width: 100vw;
}

#homeText {
    font-size: 4rem;
    /* padding: 0.5rem; */
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    letter-spacing: 0.1rem;
    min-width: 100vw;
}

.container p span.cursor {
    display: inline-block;
    background-color: #000;
    margin-left: 0rem;
    width: 3px;
    animation: blink 1s infinite;
}

.navbar {
    align-items: center;
}

@keyframes blink {
    0% {
        background-color: #000;
    }
    49% {
        background-color: #000;
    }
    50% {
        background-color: transparent;
    }
    99% {
        background-color: transparent;
    }
    100% {
        background-color: #000;
    }
}
/* @keyframes test {
    50% {
        letter-spacing: 10px;
    }
} */
@media screen and (max-width: 600px) {
    .container p {
        font-size: 2rem;
        /* padding: 0.5rem; */
        text-align: center;
        overflow: hidden;
        letter-spacing: 0.1rem;
        min-width: 100vw;
    }
}
